@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

html {
  background-color: #022b69;
  background-image: url("https://www.transparenttextures.com/patterns/60-lines.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  font-family: "Playfair Display", serif;
}

.App {
  font-family: "Playfair Display", serif;
  text-align: center;
}

.App h1 {
  font-size: 3em;
  text-transform: uppercase;
}

.contact {
  margin: 0 auto;
  padding-bottom: 90px;
  padding-left: 20%;
  padding-right: 20%;
}

.contact p {
  font-size: 1.1em;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 30px;
}

.contact p a {
  text-decoration: none;
  color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sticky-nav {
  opacity: 0;
  height: 0;
  transition: 0.5s all;
}

.sticky-nav h1 {
  font-size: 1.5em;
  text-transform: uppercase;
}

.show-nav {
  opacity: 1;
  background-color: #fff;
  margin: 0;
  padding: 10px;
  height: 78px;
  color: #022b69;
  font-family: "Playfair Display", serif;
  z-index: 1;
  transition: opacity 1.5s;
  box-shadow: 0px 15px 10px -15px #111;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
