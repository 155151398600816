@import "https://fonts.googleapis.com/css?family=PT+Sans:400,700";
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-family: "PT Sans";
  font-size: 18px;
}

.tiles {
  padding: 6vh 16vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.tile {
  margin: 15px;
  cursor: pointer;
  overflow: hidden;
  width: 18vw;
  height: 18vw;
}
.tile img {
  width: 100%;
  transition: transform 500ms ease;
}
.tile img:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 630px) {
  .tiles {
    padding: 6vh 0;
  }
  .title img {
    width: 80%;
    height: auto;
  }
}
