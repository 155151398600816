@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  background-color: #022b69;
  background-image: url("https://www.transparenttextures.com/patterns/60-lines.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  font-family: "Playfair Display", serif;
}

.App {
  font-family: "Playfair Display", serif;
  text-align: center;
}

.App h1 {
  font-size: 3em;
  text-transform: uppercase;
}

.contact {
  margin: 0 auto;
  padding-bottom: 90px;
  padding-left: 20%;
  padding-right: 20%;
}

.contact p {
  font-size: 1.1em;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 30px;
}

.contact p a {
  text-decoration: none;
  color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sticky-nav {
  opacity: 0;
  height: 0;
  transition: 0.5s all;
}

.sticky-nav h1 {
  font-size: 1.5em;
  text-transform: uppercase;
}

.show-nav {
  opacity: 1;
  background-color: #fff;
  margin: 0;
  padding: 10px;
  height: 78px;
  color: #022b69;
  font-family: "Playfair Display", serif;
  z-index: 1;
  transition: opacity 1.5s;
  box-shadow: 0px 15px 10px -15px #111;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-family: "PT Sans";
  font-size: 18px;
}

.tiles {
  padding: 6vh 16vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.tile {
  margin: 15px;
  cursor: pointer;
  overflow: hidden;
  width: 18vw;
  height: 18vw;
}
.tile img {
  width: 100%;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.tile img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media only screen and (max-width: 630px) {
  .tiles {
    padding: 6vh 0;
  }
  .title img {
    width: 80%;
    height: auto;
  }
}

